import React from 'react'
import Header from '../components/Header'
import ShopList from '../components/ShopList'
import Banner from '../components/Banner'

const Home = () => {
  return (
    <div>
         <Header />
         <Banner />
         <ShopList />
    </div>
  )
}

export default Home
