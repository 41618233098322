// Banner.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Banner = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#0077B6',
        borderRadius: '10px',
        color: '#fff',
        padding: '40px 0',
        marginBottom: '10px',
        textAlign: 'center',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          borderRadius: '10px',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'linear-gradient(45deg, #008000, #00C000)',
          opacity: 0.8,
          zIndex: 0,
        },
        '& > *': {
          position: 'relative',
          zIndex: 1,
        },
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontWeight: 700,
          textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
          letterSpacing: '1px',
          fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem' },
        }}
      >
        Empowering Brands, Connecting Spaces.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontStyle: 'italic',
          fontWeight: 300,
          fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.2rem' },
          letterSpacing: '0.5px',
          marginTop: '8px',
        }}
      >
        Discover the perfect space for your business to thrive.
      </Typography>
    </Box>
  );
};

export default Banner;
