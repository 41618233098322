import React, { useState, useEffect } from 'react';
import { Grid, Pagination, Box } from '@mui/material';
import ShopCard from './ShopCard';
import ShopDetailModal from './ShopDetailModal';
import { fetchShops } from '../api';

const ShopList = () => {
  const [shops, setShops] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedShop, setSelectedShop] = useState(null);

  useEffect(() => {
    const loadShops = async () => {
      try {
        const { data } = await fetchShops(page);
        console.log("fetchshops response", data)
        setShops(data.shops);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error loading shops:', error);
      }
    };

    loadShops();
  }, [page, shops]); // Only `page` is a dependency

  return (
    <>
      <Grid container spacing={3}>
        {shops.map((shop) => (
          <Grid item xs={12} sm={6} md={4} key={shop._id}>
            <ShopCard shop={shop} onClick={() => setSelectedShop(shop)} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, value) => setPage(value)}
          color="primary"
        />
      </Box>
      {selectedShop && (
        <ShopDetailModal
          shop={selectedShop}
          open={Boolean(selectedShop)}
          onClose={() => setSelectedShop(null)}
        />
      )}
    </>
  );
};

export default ShopList;
