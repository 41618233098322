import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Grid,
  IconButton,
  Box,
  Button,
} from '@mui/material';
import { deleteShop } from '../api';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const ShopDetailModal = ({ shop, open, onClose, onShopDeleted }) => {
  const allImages = [shop.mainImage, ...shop.additionalImages];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === allImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? allImages.length - 1 : prevIndex - 1
    );
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteShop(shop._id);
      onClose();
      if (onShopDeleted) {
        onShopDeleted(shop);
      }
    } catch (error) {
      console.error('Error deleting shop:', error);
    } finally {
      setDeleteConfirmOpen(false);
    }
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" fontWeight="bold">
          {shop.name}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '400px',
                backgroundColor: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={allImages[currentImageIndex]}
                alt={`${shop.name} ${currentImageIndex + 1}`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
              />
              
              <IconButton
                onClick={handlePrevious}
                sx={{
                  position: 'absolute',
                  left: 8,
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }
                }}
              >
                <KeyboardArrowLeft />
              </IconButton>
              
              <IconButton
                onClick={handleNext}
                sx={{
                  position: 'absolute',
                  right: 8,
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }
                }}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                mt: 2,
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '4px',
                },
              }}
            >
              {allImages.map((image, index) => (
                <Box
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}
                  sx={{
                    width: '80px',
                    height: '60px',
                    flexShrink: 0,
                    cursor: 'pointer',
                    border: index === currentImageIndex ? '2px solid #1976d2' : '2px solid transparent',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                >
                  <img
                    src={image}
                    alt={`thumbnail ${index + 1}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mt: 3, p: 2, backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
              <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                Shop Details
              </Typography>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography variant="body1" fontWeight="600" sx={{ minWidth: '100px' }}>
                  Location:
                </Typography>
                <Typography variant="body1">{shop.location}</Typography>
              </Box>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography variant="body1" fontWeight="600" sx={{ minWidth: '100px' }}>
                  Rent:
                </Typography>
                <Typography variant="body1">₹{shop.rent}</Typography>
              </Box>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography variant="body1" fontWeight="600" sx={{ minWidth: '100px' }}>
                  Area:
                </Typography>
                <Typography variant="body1">{shop.area} sq ft</Typography>
              </Box>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography variant="body1" fontWeight="600" sx={{ minWidth: '100px' }}>
                  Description:
                </Typography>
                <Typography variant="body1">{shop.description}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={onClose}>Close</Button>
      <Button color="error" onClick={handleDeleteClick}>
        Delete
      </Button>
    </DialogActions>

    {/* Confirmation dialog */}
    <Dialog
      open={deleteConfirmOpen}
      onClose={() => setDeleteConfirmOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm Delete
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this shop?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteConfirmOpen(false)}>
          Cancel
        </Button>
        <Button color="error" onClick={handleDeleteConfirm} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    </Dialog>
  );
};

export default ShopDetailModal;
