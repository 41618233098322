import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  Grid, 
  IconButton,
  Box,
  Typography 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createShop } from '../api';

const AddShopModal = ({ open, onClose, onShopAdded }) => {
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    rent: '',
    area: '',
    description: ''
  });
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setImages(Array.from(e.target.files));
  };

  const resetForm = () => {
    setFormData({
      name: '',
      location: '',
      rent: '',
      area: '',
      description: ''
    });
    setImages([]);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const form = new FormData();
      
      // Append form data
      Object.keys(formData).forEach(key => {
        form.append(key, formData[key]);
      });

      // Append images
      images.forEach(image => {
        form.append('images', image);
      });

      const response = await createShop(form);

      // Reset form and close modal
      resetForm();
      onClose();
      
      // Notify parent component that a new shop was added
      if (onShopAdded) {
        onShopAdded(response.data);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Error creating shop. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Add New Shop
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Shop Name"
                fullWidth
                required
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                name="location"
                label="Location"
                fullWidth
                required
                value={formData.location}
                onChange={handleChange}
              />
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                name="rent"
                label="Rent"
                type="number"
                fullWidth
                required
                value={formData.rent}
                onChange={handleChange}
                InputProps={{
                  startAdornment: '₹'
                }}
              />
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                name="area"
                label="Area (sq ft)"
                type="number"
                fullWidth
                required
                value={formData.area}
                onChange={handleChange}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                multiline
                rows={4}
                fullWidth
                required
                value={formData.description}
                onChange={handleChange}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Box>
                <input
                  accept="image/*"
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="shop-images"
                />
                <label htmlFor="shop-images">
                  <Button variant="contained" component="span">
                    Upload Images
                  </Button>
                </label>
                {images.length > 0 && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {images.length} {images.length === 1 ? 'image' : 'images'} selected
                  </Typography>
                )}
              </Box>
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained" 
            disabled={loading || !images.length}
          >
            {loading ? 'Creating...' : 'Create Shop'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddShopModal;