import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import AddShopModal from './AddShopModal';

const Header = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Add any logout logic here
    navigate('/');
  };

  return (
    <>
      <AppBar position="static" sx={{ mb: 2, backgroundColor: '#FFDF00' }}>
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <img
              src="/boader-logo.png"
              alt="Brand Logo"
              style={{
                height: '40px',
                width: 'auto',
                objectFit: 'contain'
              }}
            />
          </div>
          {isSmallScreen ? (
            <>
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setOpen(true);
                    handleMenuClose();
                  }}
                >
                  Add Shop
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button
                color="inherit"
                onClick={() => setOpen(true)}
                sx={{
                  fontWeight: 600,
                  fontSize: '1rem',
                  letterSpacing: '0.5px',
                  padding: '8px 24px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  border: '2px solid white',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    border: '2px solid white'
                  }
                }}
              >
                Add Shop
              </Button>
              <Button
                color="inherit"
                onClick={handleLogout}
                sx={{
                  fontWeight: 600,
                  fontSize: '1rem',
                  letterSpacing: '0.5px',
                  padding: '8px 24px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  border: '2px solid white',
                  marginLeft: '16px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    border: '2px solid white'
                  }
                }}
              >
                Logout
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <AddShopModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Header;