import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

const ShopCard = ({ shop, onClick }) => {
  return (
    <Card onClick={onClick} sx={{ cursor: 'pointer' }}>
      <CardMedia
        component="img"
        height="200"
        image={shop.mainImage}
        alt={shop.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {shop.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Location: {shop.location}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Rent: ₹{shop.rent}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Area: {shop.area} sq ft
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ShopCard;
