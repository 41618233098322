import axios from 'axios';

const API = axios.create({ 
    baseURL: 'https://shop-listing-app.onrender.com',
    withCredentials: true
});

export const fetchShops = (page) => API.get(`/shops?page=${page}`);
export const createShop = (formData) => API.post('/shops', formData);
export const getShopById = (id) => API.get(`/shops/${id}`);
export const deleteShop = (id) => API.delete(`/shops/${id}`);